<template>
  <div>
    <div class="header">
      <div class="title-box">
        <p class="title">{{ config.label }}</p>
        <intro-default cont="路珠图展示近期每期的开奖结果，请从左到右、自上而下查看路珠图，当连续开出的同一形态中断时，则另起一列显示；表格最左一列为最新一期结果。"></intro-default>
      </div>
      <div class="search-box">
        <select-user type="1" @change="page_change" :page="30"></select-user>
        <!-- <el-checkbox v-model="fiveLine" class="checkbox">五珠线</el-checkbox> -->
      </div>
    </div>
    <div class="lz_cont">
      <ul class="types" v-if="typeTitles">
        <li :class="{ active: i === typeIndex }" v-for="(option, i) in typeTitles" :key="option" @click="typeIndex = i">
          {{ option }}</li>
      </ul>
      <div class="tool lz_tools" v-if="titles.length !== 0">
        <div class="options">
          <div class="group">
            <el-checkbox v-for="el in type_data" :key="el.dataIndex" v-model="el.selected" class="checkbox">{{ el.label
              }}</el-checkbox>
          </div>
        </div>
        <div class="btns">
          <div class="btn" @click="allSelectAction(true)">
            <i class="el-icon-check"></i>
            全选
          </div>
          <div class="btn" @click="allSelectAction(false)">
            <i class="el-icon-close"></i>
            清除
          </div>
        </div>
      </div>
      <div class="table" v-for="table in tableDatas" :key="table.name">
        <div class="table-header">
          <span class="title">{{ table.name }}</span>
          <div>
            <span>摊路{{ (table['column'] || []).length }}：</span>
            <template v-for="count in table.count">
              <span>{{ count['label'] }}</span>
              <span class="value">{{ count['count'] }}</span>
            </template>
          </div>
        </div>
        <div class="table-body">
          <div class="td" v-for="(column, kk) in table.column" :key="column.id">
            <div v-for="(el, i) in column.list" :key="i" class="tr"
              :class="{ 'five-line': fiveLine, 'newFont': getNewFont(column.list, i, kk) }"
              :style="cellStyleWithValue(el)">{{
                el }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DatePicker, Checkbox } from 'element-ui'
import moment from 'moment'
import randomString from '@plugins/random'
import configs from '@config/lz'

export default {
  name: 'LZNumber',
  components: {
    [DatePicker.name]: DatePicker,
    [Checkbox.name]: Checkbox
  },
  props: {
    code: String,
    type: String
  },
  data() {
    return {
      type_data: [],
      per_page: 30,
      result: null,
      titleData: null,
      loading: null,
      date: Date.now(),
      fiveLine: true,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      },
      typeIndex: 0
    }
  },
  computed: {
    config() {
      return configs[this.type]
    },
    titles() {
      if (!this.data) return []
      return this.data.titles
    },
    isComposite() {
      if (!this.result) return false
      if (this.result.list.length === 0) return false
      return 'list' in this.result.list[0]
    },
    typeTitles() {
      if (!this.result) return null
      if (this.isComposite) {
        return this.result.titles
      } else {
        return null
      }
    },
    data() {
      if (!this.result) return null
      if (this.isComposite) {
        return this.result.list[this.typeIndex]
      } else {
        return this.result
      }
    },
    tableDatas() {
      var array = []
      if (!this.data) return array
      if (this.titleData[0].length === 0 && this.titleData[1].length === 0) {
        for (let i = 0; i < this.data.list.length; i++) {
          const list = this.data.list[i]

          const table = {
            name: this.config.after,
            column: [],
            count: []
          }
          let lastValue = ''
          let lastObj = {
            list: [],
            id: randomString(7)
          }
          for (const value of list) {
            if (lastValue === value) {
              lastObj.list.push(value)
            } else {
              lastObj = {
                list: [],
                id: randomString(7)
              }
              lastObj.list.push(value)
              table.column.push(lastObj)
            }
            lastValue = value
          }
          table.count = this.getCountNumber(this.config.resultCountFn(this.code, this.typeIndex, this.config.after), list, table.column)
          array.push(table)
        }
      } else {
        for (const titles of this.titleData) {
          for (const obj of titles) {
            if (!obj.selected) continue
            const i = obj.dataIndex
            const list = this.data.list[i]
            const table = {
              name: obj.label,
              column: [],
              count: []
            }
            let lastValue = ''
            let lastObj = {
              list: [],
              id: randomString(7)
            }
            for (const value of list) {
              if (lastValue === value) {
                lastObj.list.push(value)
              } else {
                lastObj = {
                  list: [],
                  id: randomString(7)
                }
                lastObj.list.push(value)
                table.column.push(lastObj)
              }
              lastValue = value
            }
            table.count = this.getCountNumber(this.config.resultCountFn(this.code, this.typeIndex, obj.label), list, table.column)
            array.push(table)
          }
        }
      }
      return array
    }
  },
  watch: {
    code: function (val, oldVal) {
      this.per_page = 30
      this.reqeustGetData()
    },
    type: function (val, oldVal) {
      this.reqeustGetData()
    },
    date: function (val, oldVal) {
      this.reqeustGetData()
    },
    typeIndex: function (val, oldVal) {
      this.setupTitleData()
    }
  },
  mounted() {
    this.reqeustGetData()
  },
  methods: {
    getCountNumber(lzKeys, list, columnArray) {
      let rlt = []
      if (lzKeys && lzKeys.length > 0) {
        for (const key of lzKeys) {
          if (typeof (key) === 'string') {
            const count = list.reduce((acc, cur) => {
              if (cur === key) {
                return acc + 1
              } else {
                return acc
              }
            }, 0)
            rlt.push({
              label: key,
              count
            })
          } else {
            const { label, func } = key
            const count = func(columnArray)
            rlt.push({
              label,
              count
            })
          }
        }
      }
      return rlt
    },
    getNewFont(list, k, pk) {
      if (pk === 0 && ((list || []).length - 1) === k) return true
      return false
    },
    page_change(value) {
      this.per_page = value
      this.reqeustGetData()
    },
    refresh() {
      this.reqeustGetData()
    },
    setupTitleData() {
      var data = []
      var array = []
      if (['number', 'big_small', 'single_double'].indexOf(this.type) !== -1) {
        var firstArray = []
        for (let i = 0; i < this.data.titles.length; i++) {
          const element = this.data.titles[i]
          const obj = {
            label: element,
            selected: i <= 2,
            dataIndex: i
          }
          if (['冠亚和', '三四和', '五六和', '七八和', '九十和'].indexOf(element) !== -1) {
            firstArray.push(obj)
          } else {
            array.push(obj)
          }
        }
        data.push(firstArray)
        data.push(array)
      } else {
        for (let i = 0; i < this.data.titles.length; i++) {
          const element = this.data.titles[i]
          const obj = {
            label: element,
            selected: i <= 2,
            dataIndex: i
          }
          array.push(obj)
        }
        data.push(array)
      }
      this.titleData = data
      this.type_data = []
      let t_arr = []
      const len = data.length - 1
      for (let i = len; i >= 0; i--) {
        t_arr = t_arr.concat(data[i])
      }
      this.type_data = t_arr
    },
    allSelectAction(selected) {
      for (const group of this.titleData) {
        for (const obj of group) {
          obj.selected = selected
        }
      }
    },
    reqeustGetData() {
      this.$api.roadBead(this.code, this.per_page, this.type).then((res) => {
        const data = res.data
        if (data['titles'] && data['titles'].length === 1 && data['titles'][0] == '和值') {
          data['titles'] = ['总和']
        }
        this.result = data
        this.setupTitleData()
      }).catch((err) => {
        this.$message.error(err)
      })
    },
    cellStyleWithValue(value) {
      if (!this.config.colors) return null
      for (const color of this.config.colors) {
        if (color.value === value) {
          return { color: color.color }
        }
      }
      return null
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@scss/pc/variables';

.header {
  padding: 0 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  border-bottom: thin $color-border solid;

  >.title-box {
    display: flex;
    align-items: center;
    height: 49px;

    >.title {
      font-size: 20px;
      font-weight: 400;
      color: #222;
    }
  }

  >.search-box {
    display: flex;
    align-items: center;

    >.title {
      margin-right: 12px;
      font-size: 14px;
      color: #222;
    }

    >.checkbox {
      margin-left: 20px;
    }
  }
}

.lz_cont {
  padding: 20px;
  padding-top: 30px;
  padding-bottom: 0;
  box-sizing: border-box;
}

.info {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
  margin-top: 15px;

  span {
    font-weight: bold;
  }
}

.tool {
  margin-bottom: 14px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  >.options {
    >.group {
      margin-bottom: 16px;
      display: flex;
      align-items: center;

      >.checkbox {
        min-width: 57px;
        margin-right: 9px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  >.btns {
    display: flex;

    >.btn {
      margin-left: 14px;
      margin-bottom: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60px;
      height: 22px;
      border: 1px solid #E3E3E6;
      border-radius: 4px;
      font-size: 12px;
      font-weight: 400;
      color: #222;
      white-space: nowrap;
      cursor: pointer;

      >i {
        margin-right: 6px;
        color: #979799;
      }
    }
  }
}

.types {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  >li {
    cursor: pointer;
    position: relative;
    font-size: 16px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.8);
    height: 34px;
    line-height: 34px;

    &.active {
      color: #fa3e3e;

      &::after {
        content: "";
        display: block;
        position: absolute;
        width: 43px;
        height: 3px;
        background: #fa3e3e;
        box-shadow: 0px 2px 4px 0px rgba(250, 62, 62, 0.2);
        border-radius: 8px;
        left: 50%;
        transform: translate(-50%);
        bottom: 0;
      }
    }

    &+li {
      margin-left: 35px;
      font-weight: 500;
    }
  }
}

.table {
  border: 1px solid rgba(216, 216, 216, 0.5);

  &+.table {
    margin-top: 20px;
  }

  >.table-header {
    background-color: #F1F2F3;
    height: 44px;
    border-bottom: 1px solid rgba(216, 216, 216, 0.5);
    display: flex;
    padding-left: 10px;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    color: rgba(34, 34, 34, 0.8);
    font-weight: 400;

    >i {
      margin-right: 6px;
      color: #FA3E3E;
      font-weight: bold;
    }

    .new,
    .value {
      color: #FA3E3E;

    }

    .new {
      margin-right: 12px;
    }

    .value {
      margin-left: 5px;
      margin-right: 10px;

      &:before {
        content: "\005b\0020";
      }

      &:after {
        content: "\0020\005d\0020";
      }
    }
  }

  >.table-body {
    display: flex;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

    >.td {
      // width: 30px;
      min-height: 180px;
      flex-shrink: 0;
      flex-grow: 0;

      &+.td {
        border-left: 1px solid rgba(216, 216, 216, 0.5);
      }

      >.tr {
        min-width: 32px;
        font-size: 14px;
        text-align: center;
        line-height: 36px;
        color: #222;
        font-weight: 400;
      }

      .five-line:nth-child(5n) {
        border-bottom: 1px solid #A56503;
      }
    }
  }
}

.newFont {
  font-weight: bold !important;
  animation: blink 1.8s infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.4;
  }

  100% {
    opacity: 1;
  }
}
</style>

<style lang="scss">
.lz_tools .el-checkbox__label {
  font-size: 12px !important;
}
</style>
