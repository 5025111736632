import lotteryCodeLength from './lottery-code-length'

export default {
  number: {
    label: '号码路珠',
    enLabel: 'Number Luzhu',
    after: '号码',
    columnWidth: 30,
    resultCountFn: () => {
      return [
        {
          label: '号码统计',
          func: function (array) {
            return array.length
          }
        }
      ]
    }
  },
  big_small: {
    label: '大小路珠',
    enLabel: 'Luzhu',
    after: '大小',
    columnWidth: 30,
    colors: [
      {
        value: '大',
        color: '#FA3E3E'
      },
      {
        value: '小',
        color: '#0091FF'
      },
      {
        value: '尾大',
        color: '#FA3E3E'
      },
      {
        value: '尾小',
        color: '#0091FF'
      },
      {
        value: '和',
        color: '#FFCC00'
      }
    ],
    resultCountFn: (code, type, subType) => {
      let rlt = ['大', '小']
      const len = lotteryCodeLength(code)
      if (len === 8 || len === 7 || len === 4) {
        if (type === 1) rlt = ['尾大', '尾小']
      }
      if (len === 8 && type === 0 && subType == '总和') rlt.push('和')
      if (len === 7 && subType != '总和') rlt.push('和')
      return rlt
    }
  },
  single_double: {
    label: '单双路珠',
    enLabel: 'Single and double',
    after: '单双',
    columnWidth: 30,
    colors: [
      {
        value: '单',
        color: '#FA3E3E'
      },
      {
        value: '双',
        color: '#0091FF'
      },
      {
        value: '合单',
        color: '#FA3E3E'
      },
      {
        value: '合双',
        color: '#0091FF'
      },
      {
        value: '和',
        color: '#FFCC00'
      }
    ],
    resultCountFn: (code, type, subType) => {
      let rlt = ['单', '双']
      const len = lotteryCodeLength(code)
      if (len === 8 || len === 7 || len === 4) {
        if (type === 1) rlt = ['合单', '合双']
      }
      if (len === 7 && subType != '总和') rlt.push('和')
      return rlt
    }
  },
  loong_tiger: {
    label: '龙虎路珠',
    enLabel: 'Longhu Luzhu',
    after: '',
    columnWidth: 30,
    colors: [
      {
        value: '龙',
        color: '#FA3E3E'
      },
      {
        value: '虎',
        color: '#0091FF'
      }
    ],
    resultCountFn: (code, type, subType) => {
      let rlt = ['龙', '虎']
      const len = lotteryCodeLength(code)
      if (len === 5) rlt.push('和')
      return rlt
    }
  },
  combination: {
    label: '组合路珠',
    enLabel: 'Single and double',
    after: '',
    columnWidth: 44,
    colors: [
      {
        value: '小单',
        color: '#FA3E3E'
      },
      {
        value: '大单',
        color: '#FA3E3E'
      },
      {
        value: '大双',
        color: '#0091FF'
      },
      {
        value: '小双',
        color: '#0091FF'
      }
    ],
    resultCountFn: () => {
      return ['小双', '大双', '小单', '大单']
    }
  },
  before_after: {
    label: '前后路珠',
    enLabel: 'Before and after',
    after: '',
    columnWidth: 30,
    colors: [
      {
        value: '前',
        color: '#FA3E3E'
      },
      {
        value: '后',
        color: '#0091FF'
      }
    ],
    resultCountFn: () => {
      return ['前', '后']
    }
  },
  zodiac: {
    label: '生肖路珠',
    enLabel: 'Zodiac Luzhu',
    after: '',
    columnWidth: 30,
    resultCountFn: () => {
      return [
        {
          label: '生肖统计',
          func: function (array) {
            return array.length
          }
        }
      ]
    }
  },
  yin_yang: {
    label: '阴阳路珠',
    enLabel: 'YinYang Luzhu',
    after: '',
    columnWidth: 30,
    colors: [
      {
        value: '阳',
        color: '#FA3E3E'
      },
      {
        value: '阴',
        color: '#0091FF'
      }
    ],
    resultCountFn: () => {
      return ['阴', '阳']
    }
  },
  animals: {
    label: '禽兽路珠',
    enLabel: 'Animals Luzhu',
    after: '',
    columnWidth: 30,
    colors: [
      {
        value: '禽',
        color: '#FA3E3E'
      },
      {
        value: '兽',
        color: '#0091FF'
      }
    ],
    resultCountFn: () => {
      return ['禽', '兽']
    }
  },
  five_shapes: {
    label: '五行路珠',
    enLabel: 'Five Shapes Luzhu',
    after: '',
    columnWidth: 30,
    colors: [
      {
        value: '金',
        color: '#FFD700'
      },
      {
        value: '木',
        color: '#8B4513'
      },
      {
        value: '水',
        color: '#00BFFF'
      },
      {
        value: '火',
        color: '#FF0000'
      },
      {
        value: '土',
        color: '#696969'
      }
    ],
    resultCountFn: () => {
      return ['金', '木', '水', '火', '土']
    }
  },
  eight_trigrams: {
    label: '八卦路珠',
    enLabel: 'Eight Trigrams Luzhu',
    after: '',
    columnWidth: 30,
    resultCountFn: () => {
      return ['乾', '坤', '震', '巽', '坎', '离', '艮', '兑']
    }
  },
  decor: {
    label: '花色路珠',
    enLabel: 'Decor Luzhu',
    after: '',
    columnWidth: 44,
    colors: [
      {
        value: '方块',
        color: '#FF0000'
      },
      {
        value: '红桃',
        color: '#FF0000'
      }
    ],
    resultCountFn: () => {
      return ['方块', '梅花', '红桃', '黑桃']
    }
  },
  sum: {
    label: '总和路珠',
    enLabel: 'Sum Luzhu',
    after: '',
    columnWidth: 30,
    colors: [
      {
        value: '大',
        color: '#FA3E3E'
      },
      {
        value: '小',
        color: '#0091FF'
      },
      {
        value: '单',
        color: '#FA3E3E'
      },
      {
        value: '双',
        color: '#0091FF'
      }
    ],
    resultCountFn: () => {
      return []
    }
  },
  pattern: {
    label: '形态路珠',
    enLabel: 'XingTai Luzhu',
    after: '',
    columnWidth: 30,
    colors: [
      {
        value: '二同号',
        color: '#FA3E3E'
      },
      {
        value: '三不同',
        color: '#0091FF'
      }
    ],
    resultCountFn: () => {
      return ['二同号', '三不同']
    }
  },
  tail: {
    label: '尾数路珠',
    enLabel: 'Tail Luzhu',
    after: '',
    columnWidth: 30,
    colors: [
    ],
    resultCountFn: () => {
      return [
        {
          label: '号码统计',
          func: function (array) {
            return array.length
          }
        }
      ]
    }
  },
  bose: {
    label: '波色路珠',
    enLabel: 'Ball Color Luzhu',
    after: '',
    columnWidth: 30,
    colors: [
      {
        value: '红波',
        color: '#FA3E3E'
      },
      {
        value: '蓝波',
        color: '#0091FF'
      },
      {
        value: '绿波',
        color: 'green'
      }
    ],
    resultCountFn: () => {
      return ['红波', '蓝波', '绿波']
    }
  }
}
